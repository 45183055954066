import React from 'react';
import Button from 'components/atoms/button/index.jsx';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';
import './HowToBuyBanner.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

export default class HowToBuyBanner extends React.Component {
  render() {
    let className = 'howToBuyBanner';

    return (
      <div className={className}>
        <Desktop>
          <div className="container">
            <div className="col col-three col-m-twelve" />
            <div className="col col-two col-m-twelve">
              <Link to="/como-reservar" className="button-link">
                <Button fluid variant="main" text="Mirá el video" />
              </Link>
            </div>
            <div className="col col-six col-m-twelve">
              <p>
                Reservar tu 0km online en solo unos minutos y sin salir de casa.{' '}
                <br /> Mirá el video y aprendé como estar más cerca de tu 0km.
              </p>
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="container">
            <div>
              <div className="col col-three"></div>
              <div className="col col-twelve col-center">
                <h3>Reservar Online es muy fácil</h3>
                <p>
                  Reservar tu 0km online en solo unos minutos y sin salir de
                  casa. Mirá el video y aprendé como estar más cerca de tu 0km.
                </p>
              </div>
            </div>
            <div>
              <div className="col col-three"></div>
              <div className="col col-twelve col-center">
                <Link to="/como-reservar" className="button-link">
                  <Button variant="main" text="Mirá el video" />
                </Link>
              </div>
            </div>
          </div>
        </Mobile>
      </div>
    );
  }
}
