import React from 'react';
import { display as displayPrice } from 'helpers/price';

import './PlanCard.scss';

export default class PlanCard extends React.Component {
  render() {
    const { plan, image, link, disabled, onClick } = this.props;

    let className = 'plan-card' + (disabled ? ' plan-card-disabled' : '');

    return (
      <div className={className}>
        <a
          href={link}
          onClick={onClick}
          className="plan-card__link"
          rel="noopener noreferrer"
          target="_blank"
        >
          Ver unidades disponibles
        </a>

        <h5 className="plan-card__code">{plan.name}</h5>
        <h3 className="plan-card__name">{plan.model}</h3>
        <div className="plan-card__image">
          <img src={image} alt={plan.name} loading="lazy" />
        </div>
        <div className="plan-card__details">
          <div className="plan-card__detail">
            <div className="plan-card__detail__name">Meses</div>
            <div className="plan-card__detail__value">{plan.installments}</div>
          </div>

          {plan.initial_installment > 0 && (
            <div className="plan-card__detail">
              <div className="plan-card__detail__name">Cuota de adhesión</div>
              <div className="plan-card__detail__value">
                {displayPrice(plan.initial_installment)}
              </div>
            </div>
          )}

          {!plan.initial_installment && (
            <div className="plan-card__detail">
              <div className="plan-card__detail__name">Cuota inicial</div>
              <div className="plan-card__detail__value">
                {displayPrice(plan.first_installment)}
              </div>
            </div>
          )}

          <div className="plan-card__detail">
            <div className="plan-card__detail__name">Operatoria</div>
            <div className="plan-card__detail__value">{plan.integration}</div>
          </div>
          <div className="plan-card__detail">
            <div className="plan-card__detail__value plan-card__detail__value-text ">
              {plan.description}
            </div>
          </div>
        </div>
        <div className="plan-card__arrowlink">Suscribirse</div>
      </div>
    );
  }
}
