import React from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/button";
import "./StoriesBlock.scss";

function getClass(story) {
  switch (story.brand_string) {
    case "chevrolet":
      return "gm";
    case "volkswagen":
      return "vw";
    default:
      return story.brand_string;
  }
}

export default function StoriesBlock({
  stories,
  title = "Territorio Yacopini",
  seeStory,
}) {
  if (!stories?.length) {
    return null;
  }

  return (
    <>
      <div className="stories-block">
        <div className="container">
          <h5>Historias {title}</h5>
        </div>
        <div className="stories-scroll">
          <div className="stories-container">
            {stories.map((story) => (
              <div className={`story ${getClass(story)}`} key={story.id}>
                <Link to={story.slug_url}>
                  <div className="story-data">
                    <h4>{story.title}</h4>
                    <h5>
                      {story.brand_string} / {story.category_string}
                    </h5>
                    <div className="story-data-action">
                      <Button
                        text="Ver historia completa"
                        small
                        variant="main"
                        fluid
                      />
                    </div>
                  </div>
                  <div className="story-video-preview">
                    <video
                      muted
                      loop
                      onMouseOver={(event) => event.target.play()}
                    >
                      <source
                        src={`https://territorioyacopini.com.ar/${story.video_path}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
