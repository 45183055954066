import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Responsive from 'react-responsive';
import Header from 'components/organisms/header/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Hero from 'components/organisms/hero/index.jsx';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import ContactForm from 'components/organisms/contactForm';
import contactBackground from 'resources/img/contact-background.jpg';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import './Contact.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

class Contact extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="contact">
        <Helmet>
          <title>Contactanos</title>
          <meta
            name="description"
            content="Territorio Yacopini - Concesionario oficial Volkswagen, Chevrolet, Nissan y Ducati"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <Desktop>
          <Header active={5} />
        </Desktop>
        <Mobile>
          <MobileNavigation />
        </Mobile>
        <Hero
          type="background"
          big
          image={contactBackground}
          imageAlt="Contactanos"
        >
          <div className="col col-five col-m-twelve">
            <Desktop>
              <br />
              <br />
            </Desktop>
            <h2>San Martín Sur 600, Godoy Cruz, Mendoza.</h2>
            <p className="p p-b">
              Visitá nuestras oficinas de lunes a sábado o llamanos al +54 261
              4439300.
            </p>
            <p>
              <Arrowlink
                text="Ver mapa"
                onClick={() => window.open('https://goo.gl/maps/Pv5wkLwZ4pB2')}
              />
            </p>
          </div>
        </Hero>

        <div className="section section-big section-white contact__info">
          <div className="section section-small">
            <div className="container">
              <div className="col col-twelve ">
                <h5>Territorio Yacopini</h5>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Call Center</h4>
                  <h3>+54 261 4439300</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>WhatsApp</h4>
                  <h3>
                    {' '}
                    <a
                      href="https://web.whatsapp.com/send?phone=542614687996"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +54 261 4687996
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="container contact__section">
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="contact__hours">
                  <h4>Horarios Ventas y Planes</h4>
                  <p>Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00</p>
                  <p>Sábados: 10:00 a 13:00</p>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="contact__hours">
                  <h4>Horarios Administración</h4>
                  <p>
                    Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00<br />{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-small">
            <div className="container ">
              <div className="col col-twelve ">
                <h5>Volkswagen</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Postventa Volkswagen</h4>
                  <h3>+54 261 4439355</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Repuestos Volkswagen</h4>
                  <h3>+54 261 4429898</h3>
                </div>
              </div>
            </div>
            <div className="container contact__section ">
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="contact__hours">
                  <h4>Horarios Postventa Mendoza</h4>
                  <p>Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00</p>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="contact__hours">
                  <h4>Horarios Postventa San Rafael</h4>
                  <p>Lunes a Viernes 8:00 a 13:00 / 17:00 a 21:00</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-small">
            <div className="container  contact__section">
              <div className="col col-twelve ">
                <h5>Chevrolet</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-six col-m-twelve">
                <div className="contact__phone contact__phone-big">
                  <h4>Horarios Postventa</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                </div>
              </div>
            </div>
          </div>

          <div className="section section-small">
            <div className="container  ">
              <div className="col col-twelve ">
                <h5>Nissan</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Recepción/Ventas</h4>
                  <h3>+54 261 4429824</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Teléfono Servicio</h4>
                  <h3>+54 261 4429822</h3>
                </div>
              </div>
            </div>
            <div className="container contact__section">
              <div className="col col-four col-m-twelve">
                <br />
                <div className="contact__phone contact__phone-big">
                  <h4>Horarios Postventa y Administración</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                </div>
              </div>

              <div className="col col-four col-m-twelve">
                <br />
                <div className="contact__phone contact__phone-big">
                  <h4>Horarios Ventas</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                  <p>Sábados: 10:00 a 13:00</p>
                </div>
              </div>

              <div className="col col-four col-m-twelve">
                <br />
                <div className="contact__phone contact__phone-big">
                  <h4>Oficinas / Showroom</h4>
                  <p>
                    Av. San Martín Sur 719. Godoy Cruz, Mendoza, Argentina.{' '}
                    <a
                      href="https://goo.gl/maps/c2nVJQTBeDN2"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver en mapa
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section section-small">
            <div className="container  ">
              <div className="col col-twelve ">
                <h5>Ducati</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Call Center</h4>
                  <h3>+54 261 4439300</h3>
                </div>
              </div>
              <div className="col col-eight col-m-twelve ">
                <div className="contact__phone contact__phone-big">
                  <h4>Oficinas.</h4>
                  <p>
                    San Martin Sur 600, Godoy Cruz, Mendoza.{' '}
                    <a
                      href="https://goo.gl/maps/pZzKw5AH41K2"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver en mapa
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section section-alt section-big"
          ref={section => {
            this.contact = section;
          }}
        >
          <ContactForm subject="Formulario de contacto">
            <br />
            <h2>¿Necesitas ayuda?</h2>
            <p>
              Dejanos tus datos y te contactarémos muy pronto para brindarte el
              mejor asesoramiento.O visitanos en nuestras oficinas en San
              Martín Sur 600, Godoy Cruz, Mendoza.
            </p>
          </ContactForm>
        </div>
        <Footer alt />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
