import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Responsive from "react-responsive";
import Button from "components/atoms/button/index.jsx";
import DropdownButton from "components/atoms/dropdownButton/index.jsx";
import HowToBuyBanner from "components/molecules/howToBuyBanner/index.jsx";
import Header from "components/organisms/header/index.jsx";
import MobileNavigation from "components/organisms/mobileNavigation/index.jsx";
import Footer from "components/organisms/footer/index.jsx";
import Hero from "components/organisms/hero/index.jsx";
import ybrand from "resources/img/logo-y-white.svg";
import modelsBanner from "resources/img/home_models_banner.jpg";
import modelsBannerMobile from "resources/img/home_models_banner_mobile.jpg";
import BestOffersMobile from "components/organisms/bestOffersMobile/index.jsx";
import servicesImage from "resources/img/services.png";
import servicesImageMobile from "resources/img/services-mobile.png";
import companyBackground from "resources/img/company-background@2x.png";
import companyLogos from "resources/img/company-logos@2x.png";
import ContactBlock from "./ContactBlock";
import WorkForUs from "./WorkForUs";
import routes from "config/routes";
import HomeNews from "./HomeNews";
import StoriesBlock from "components/organisms/storiesBlock";
import "./Home.scss";

const BestOffersCarousel = React.lazy(() =>
  import("components/organisms/bestOffersCarousel/index.jsx")
);

const Desktop = (props) => <Responsive {...props} minWidth={1152} />;
const Mobile = (props) => <Responsive {...props} maxWidth={1151} />;
const DesktopHeader = (props) => <Responsive {...props} minWidth={1024} />;
const MobileHeader = (props) => <Responsive {...props} maxWidth={1023} />;

class Home extends React.Component {
  state = {
    resumeSuccessDisplayed: false,
    showModal: true,
    lazy: false,
  };

  componentDidMount() {
    if (this.props.resumeSuccess || this.props.resumeError) {
      this.setState({ resumeSuccessDisplayed: true });
    }
    this.setState({ lazy: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.resumeSuccess || this.props.resumeError) &&
      !prevState.resumeSuccessDisplayed
    ) {
      this.workForUsSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    var modelsBgr = {
      backgroundImage: this.state.lazy ? "url('" + modelsBanner + "')" : "",
    };
    var modelsBgrMobile = {
      backgroundImage: this.state.lazy
        ? "url('" + modelsBannerMobile + "')"
        : "",
    };

    const shiftOptions = [
      {
        id: 0,
        title: "Turno Chevrolet",
        external: true,
        link: "https://www.territorioyacopini.com.ar/solicitar-turno/chevrolet",
      },
      {
        id: 1,
        title: "Turno Volkswagen",
        external: true,
        link:
          "https://www.territorioyacopini.com.ar/solicitar-turno/volkswagen",
      },
      {
        id: 2,
        title: "Turno Nissan",
        external: true,
        link: "https://www.territorioyacopini.com.ar/solicitar-turno/nissan",
      },
    ];

    return (
      <div className="home">
        <Mobile>
          <div className="covid-message" />
        </Mobile>
        <Helmet>
          <title>Territorio Yacopini</title>
          <meta
            name="description"
            content="Territorio Yacopini - Concesionario oficial Volkswagen, Chevrolet, Nissan y Ducati"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <DesktopHeader>
          <Header active={0} />
        </DesktopHeader>
        <MobileHeader>
          <MobileNavigation active={0} />
        </MobileHeader>
        <Desktop>
          <Hero type="color" color="orange">
            <div className="col col-six col-m-twelve home__hero">
              <h1>Oportunidades 0km</h1>
              <p className="p-b">
                Todos los días publicamos descuentos especiales en determinados
                modelos. Visitanos diariamente y no te pierdas de adquirir tu
                0km a un precio increible.
              </p>
              <br />
              <p className="p-b">
                <Link to="/oportunidades">
                  <Button text="Conocé todas las oportunidades" />
                </Link>
              </p>
            </div>
            <div className="col col-one" />
            <div className="col col-four">
              <Suspense fallback="">
                <BestOffersCarousel />
              </Suspense>
            </div>
          </Hero>
        </Desktop>

        <Mobile>
          <Hero type="color" color="orange">
            <div className="col col-twelve col-center">
              <img src={ybrand} alt="Territorio Yacopini" />
              <h1>Oportunidades</h1>
              <p className="p-n">Vehículos 0km con precios especiales.</p>
              <BestOffersMobile />
              <p className="p-b">
                <Link to="/oportunidades">
                  <Button text="Ver más oportunidades" />
                </Link>
              </p>
              <h1>Precios únicos</h1>
              <p className="p-n">
                Todos los días publicamos descuentos especiales en determinadas
                unidades. Visitanos diariamente y no te pierdas de adquirir tu
                0KM a un precio increible.
              </p>
            </div>
          </Hero>
        </Mobile>
        <HowToBuyBanner />

        <StoriesBlock stories={this.props.parameters?.stories} />

        <HomeNews news={this.props.parameters?.news} />

        {this.state.lazy && (
          <>
            <Desktop>
              <div
                className="section section-center section-image section-big home__models"
                style={modelsBgr}
              >
                <div className="container">
                  <div className="col col-six" />
                  <div className="col col-six col-m-twelve">
                    <h2>
                      Las mejores marcas,
                      <br /> todos los modelos.
                    </h2>
                    <Link to={routes.families}>
                      <Button variant="main" text="Encontrá tu 0KM" />
                    </Link>
                  </div>
                </div>
              </div>
            </Desktop>
            <Mobile>
              <div
                className="section section-center section-image section-big home__models-mobile"
                style={modelsBgrMobile}
              >
                <div className="container">
                  <div className="col col-twelve">
                    <h2>
                      Las mejores marcas,
                      <br /> todos los modelos.
                    </h2>
                    <Link to={routes.families}>
                      <Button variant="main" text="Encontrá tu 0KM" />
                    </Link>
                  </div>
                </div>
              </div>
            </Mobile>
          </>
        )}

        <div className="section section-big section-alt home__services">
          <Desktop>
            <div className="home__services__image">
              <img src={servicesImage} alt="" />
            </div>
            <div className="container">
              <div className="col col-five col-m-twelve">
                <h5>Turnos Online</h5>
                <h2>Servicios de mantenimiento</h2>
                <p>
                  Reservá un turno para realizar cualquier servicio que necesite
                  tu vehículo. Ingresá tus datos y elegí el día y el horario que
                  mejor se ajuste a sus posibilidades.
                </p>
                <br />

                <DropdownButton
                  variant="main"
                  text="Solicitá un turno"
                  options={shiftOptions}
                />

                <p className="p-xs">
                  Solicitá turno para tu Volkswagen, Chevrolet o Nissan.
                </p>
              </div>
            </div>
          </Desktop>
          <Mobile>
            <div className="container home__services-mobile">
              <div className="home__services__image-mobile">
                <img src={servicesImageMobile} alt="" />
              </div>
              <div className="col col-twelve col-center">
                <h5>Turnos Online</h5>
                <h2>Servicios de mantenimiento</h2>
                <p>
                  Reservá un turno para realizar cualquier servicio que necesite
                  tu vehículo. Ingresá tus datos y elegí el día y el horario que
                  mejor se ajuste a sus posibilidades.
                </p>
                <br />
                <DropdownButton
                  variant="main"
                  text="Solicitá un turno"
                  options={shiftOptions}
                />
                <br />
                <br />
                <p className="p-xs">
                  Solicitá turno para tu
                  <br /> Volkswagen, Chevrolet o Nissan.
                </p>
              </div>
            </div>
          </Mobile>
        </div>

        <div className="section section-big section-center home__company">
          <div className="container">
            <div className="home__company__image">
              <div className="home__company__image__background">
                <img src={companyBackground} alt="" />
              </div>
              <div className="home__company__image__logo">
                <img src={companyLogos} alt="" />
              </div>
            </div>
            <div className="col col-six col-m-twelve">
              <h2>Más de 40 años de historia</h2>
              <p>
                Yacopini cuenta con una larga trayectoria y arraigo en todo
                cuyo. Ganamos 3 Premios “Club del Presidente” como mejor
                concesionario del país y contamos con Certificación de calidad
                TÜV.
              </p>
              <br />
            </div>
          </div>
        </div>
        <ContactBlock />
        <div className="section section-big section-alt home__contact__info">
          <div className="section section-small">
            <div className="container">
              <div className="col col-twelve ">
                <h5>Territorio Yacopini</h5>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Call Center</h4>
                  <h3>+54 261 4439300</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>WhatsApp</h4>
                  <h3>
                    {" "}
                    <a
                      href="https://web.whatsapp.com/send?phone=542614687996"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +54 261 4687996
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="container home__contact__section">
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="home__contact__hours">
                  <h4>Horarios Ventas y Planes</h4>
                  <p>Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00</p>
                  <p>Sábados: 10:00 a 13:00</p>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="home__contact__hours">
                  <h4>Horarios Administración</h4>
                  <p>Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-small">
            <div className="container ">
              <div className="col col-twelve ">
                <h5>Volkswagen</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Post Volkswagen</h4>
                  <h3>+54 261 4439355</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Repuestos Volkswagen</h4>
                  <h3>+54 261 4429898</h3>
                </div>
              </div>
            </div>
            <div className="container home__contact__section ">
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="home__contact__hours">
                  <h4>Horarios Postventa Mendoza</h4>
                  <p>Lunes a Viernes 10:00 a 14:00 / 15:00 a 19:00</p>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <br />
                <div className="home__contact__hours">
                  <h4>Horarios Postventa San Rafael</h4>
                  <p>
                    Lunes a Viernes: Lunes a Viernes 8:00 a 13:00 / 17:00 a
                    21:00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-small">
            <div className="container  home__contact__section">
              <div className="col col-twelve ">
                <h5>Chevrolet</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-six col-m-twelve">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Horarios Postventa</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                </div>
              </div>
            </div>
          </div>

          <div className="section section-small">
            <div className="container  ">
              <div className="col col-twelve ">
                <h5>Nissan</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Oficinas</h4>
                  <h3>+54 261 4222848</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Recepción/Ventas</h4>
                  <h3>+54 261 4429824</h3>
                </div>
              </div>
              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Teléfono Servicio</h4>
                  <h3>+54 261 4429822</h3>
                </div>
              </div>
            </div>
            <div className="container home__contact__section">
              <div className="col col-four col-m-twelve">
                <br />
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Horarios Postventa y Administración</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                </div>
              </div>

              <div className="col col-four col-m-twelve">
                <br />
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Horarios Ventas</h4>
                  <p>Lunes a Viernes: 10:00 a 19:00</p>
                  <p>Sábados: 10:00 a 13:00</p>
                </div>
              </div>

              <div className="col col-four col-m-twelve">
                <br />
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Oficinas / Showroom</h4>
                  <p>
                    Av. San Martín Sur 719. Godoy Cruz, Mendoza, Argentina.{" "}
                    <a
                      href="https://goo.gl/maps/c2nVJQTBeDN2"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver en mapa
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section section-small">
            <div className="container  ">
              <div className="col col-twelve ">
                <h5>Ducati</h5>
              </div>

              <div className="col col-four col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Call Center</h4>
                  <h3>+54 261 4439300</h3>
                </div>
              </div>
              <div className="col col-eight col-m-twelve ">
                <div className="home__contact__phone home__contact__phone-big">
                  <h4>Oficinas.</h4>
                  <p>
                    San Martin Sur 600, Godoy Cruz, Mendoza.{" "}
                    <a
                      href="https://goo.gl/maps/pZzKw5AH41K2"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver en mapa
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section section-white section-big home__jobs"
          ref={(section) => (this.workForUsSection = section)}
        >
          <WorkForUs
            success={this.props.resumeSuccess}
            error={this.props.resumeError}
          />
        </div>
        <Footer alt />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parameters: state.parameter.all,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
